<template>
  <div>
    <section class="image-container">
      <div class="about-description">
        <img
          class="full-size-bg-img desktop-background"
          src="/images/SearchRockitAboutBanner.jpg"
          alt="banner"
        />
        <div class="about-left">
          <div class="shatter">
            <h1>Shatter Big Tech & Big Brother</h1>
            <p>
              We got tired of seeing only what Big Tech thinks we need to see,
              as it continues to get worse. What’s more, is Big Tech is
              capitalizing on its patron’s privacy rights by compiling their
              search behavior via cookies and selling it off to advertisers and
              government entities for profiling.
            </p>
          </div>
          <div class="wormhole">
            <h1>Why SearchRockit?</h1>
            <p>
              When you use SearchRockit, you are helping to build a new universe
              of unique URL’s that are not currently indexed on Big Tech
              searches. Our goal is to take searches back to what they were
              intended to be: Vast, fast with a non-personal private search
              experience.
            </p>
          </div>
          <div class="hints">
            <h1>Quick hits:</h1>
            <p>
              • Full 256-Bit HTTPS encryption<br />
              • No tracking cookies<br />
              • No data selling of any kind<br />
              • No logs
            </p>
          </div>
        </div>
        <div class="about-right">
          <div class="explore">
            <h1>Explore New Worlds of Data</h1>
            <p>
              Our uncensored button finds URL results not listed in Big Tech
              searches. These 1.2 billion unique URL’s go beyond Big Tech’s
              search and greatly enhances the quality of your results. We are
              gaining new URL’s everyday with a target of more than 2 billion in
              the not too distant future.
            </p>
          </div>
          <div class="warp">
            <h1>Warp Drive Performance</h1>
            <p>
              SearchRockit is faster than the NSA Search Engines since it does
              not install all sorts of personalization and tracking cookies on
              your system. Launch a SearchRockit query now and see the
              difference.
            </p>
          </div>
          <div>
            <h1>Going Mobile?</h1>
            <p>
              You can download SearchRockit free from the Apple App Store or Google Play.
              </p>
              <div>
              <b-button class="mr-3 mb-4" tag="a" :href="iosLink" target="_blank" type="is-red-1">iOS App Store</b-button>
              <b-button tag="a" :href="androidLink" target="_blank" type="is-red-1">Google Play</b-button>
              </div>

            
          </div>
        </div>
      </div>
    </section>

    <section class="mobile-description has-background-black-1 has-text-white">
      <div class="container py-6">
        <div class="column is-centered">
         <div class="shatter">
            <h1>Shatter Big Tech & Big Brother</h1>
            <p>
              We got tired of seeing only what Big Tech thinks we need to see,
              as it continues to get worse. What’s more, is Big Tech is
              capitalizing on its patron’s privacy rights by compiling their
              search behavior via cookies and selling it off to advertisers and
              government entities for profiling.
            </p>
          </div>
          <div class="wormhole">
            <h1>Why SearchRockit?</h1>
            <p>
              When you use SearchRockit, you are helping to build a new universe
              of unique URL’s that are not currently indexed on Big Tech
              searches. Our goal is to take searches back to what they were
              intended to be: Vast, fast with a non-personal private search
              experience.
            </p>
          </div>
          <div class="hints">
            <h1>Quick hits:</h1>
            <p>
              • Full 256-Bit HTTPS encryption<br />
              • No tracking cookies<br />
              • No data selling of any kind<br />
              • No logs
            </p>
          </div>
          <div class="explore">
            <h1>Explore New Worlds of Data</h1>
            <p>
              Our uncensored button finds URL results not listed in Big Tech
              searches. These 1.2 billion unique URL’s go beyond Big Tech’s
              search and greatly enhances the quality of your results. We are
              gaining new URL’s everyday with a target of more than 2 billion in
              the not too distant future.
            </p>
          </div>
          <div class="warp">
            <h1>Warp Drive Performance</h1>
            <p>
              SearchRockit is faster than the NSA Search Engines since it does
              not install all sorts of personalization and tracking cookies on
              your system. Launch a SearchRockit query now and see the
              difference.
            </p>
          </div>
          <div>
            <h1>Going Mobile?</h1>
            <p>
              You can download SearchRockit free from the Apple App Store or Google Play.
              </p>
              <div>
              <b-button class="mr-3 mb-4" tag="a" :href="iosLink" target="_blank" type="is-red-1">iOS App Store</b-button>
              <b-button tag="a" :href="androidLink" target="_blank" type="is-red-1">Google Play</b-button>
              </div>
          </div>
        </div>
      </div>
    </section>

    <section class="why-searchrockit has-background-light has-text-blue-6">
      <div class="container py-6">
        <div class="columns is-centered">
          <div class="column is-9-desktop">
            <div>
              <h1 class="has-text-black">
                Make SearchRockit Your Default Search Engine.
              </h1>
              <p>
                Along with lighting fast searches, you can search for images,
                videos plus a link for latest news. So, hang out with us.
              </p>
            </div>
            <div>
              <h1 class="has-text-black">SearchRockit Reactions</h1>
              <p class="reaction">
                “I love the anonymous search capability! It's so annoying to
                check out the latest TVs or PCs, then I'm bombarded with ads for
                the next two months or more.” – Ray R
              </p>
              <p>
                “Works as advertised - fast, has a button to uncensor searches
                that may be suppressed, no ads to load that slows you down. Nice
                search tool! – Nick B
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SearchInput from "@/components/SearchInput";
import SiteMenu from "@/components/SiteMenu";
export default {
  name: "About",
  components: { SearchInput, SiteMenu },
  data: () => ({
    androidLink: process.env.VUE_APP_ANDROID_APP_LINK,
    iosLink: process.env.VUE_APP_IOS_APP_LINK,
  }),
};
</script>

<style lang="scss" scoped>
//@import '@/assets/scss/_vars.scss';

.reaction {
  font-style: italic;
}
.banner {
  position: relative;
}

.about-description {
  color: white;
  position: relative;
  font-family: Montserrat, serif;

  h1 {
    font-size: 1.8em;
    font-weight: 600;
    margin-bottom: 0.5em;
  }

  p {
    font-size: 1.2em;
    margin-bottom: 30px;
  }

  .about-left {
    position: absolute;
    top: 100px;
    left: 5%;
    p {
      width: 25%;
    }
  }

  .about-right {
    position: absolute;
    top: 100px;
    left: 70%;
    p {
      width: 85%;
    }
  }
}

.mobile-description {
  display: none;
}

.why-searchrockit {
  h1 {
    font-size: 1.2em;
    font-weight: 600;
  }
  p {
    margin-top: 2em;
  }
}

@media (max-width: 1600px) {
  .about-description {
    h1 {
      font-size: 1.6em;
    }

    p {
      font-size: 1em;
    }
  }
}

@media (max-width: 1200px) {
  .about-description {
    .full-size-bg-img{
      min-height: 895px;
    }
    h1 {
      font-size: 1.4em;
    }

    p {
      font-size: 0.9em;
    }
  }
}

@media (max-width: 1000px) {
  .about-description {
        .full-size-bg-img{
          height: 384px;
    min-height: auto;
    }
    h1 {
      font-size: 1.2em;
    }

    p {
      font-size: 0.75em;
    }
  }
}

@media screen and (max-width: 1000px) {

  .banner {
    position: absolute;
  }
  .search-input {
    width: 100%;
  }
  .logo {
    display: block;
    width: 170px;
    margin-bottom: 28px;
  }
  .logo,
  .search-input {
    padding-right: 0;
  }
  .about-description {
    .about-left {
      display: none;
    }
    .about-right {
      display: none;
    }
  }

  .mobile-description {
    display: flex;
    margin-top: -7em;
    h1 {
      margin-top: 1.2em;
      margin-bottom: 0.5em;
      font-weight: 600;
      font-size: 1.2em;
    }
    p {
      font-size: 0.9em;
    }
  }
}
</style>